<template>
  <div class="box">
    <div class="content">
      <div class="banner"></div>
      <div class="main">
        <div class="lunbo">
          <el-carousel :interval="3000" arrow="always" height="282px" indicator-position="none">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
              <div class="title">{{item.journalisTitle}}</div>
              <el-image class="img" :src="echoImg(item.bannerUrl)" fit="cover" @click="goDetails('',item.id)"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <template v-for="(item,index) in subjectList" @key="index">
          <ul class="ul" :class="index<4?'ul_'+index:'ul_4'" :key="index">
            <li :class="'li_'+index">
              <div class="titleBox">
                <img class="icon" src="../images/handball-people.png"/>
                <span class="title1" :style="'color:'+color">{{item.navigationName}}</span>
                <span class="more" :style="'background:'+color" @click="more(item.navigationName,item.id,item.navigationType)">更多 +</span>
              </div>
              <ul :class="index==1?'ulBg':''">
                <template v-for="(dates,idxs) in item.journalism" @key="idxs">
                  <li v-if="index==0||index>3?idxs<8:index==1?idxs<9:index==2?idxs<3:index==3?idxs<6:idxs.length" @click="goDetails(item.navigationName, dates.id)" :key="idxs">
                    <div class="imgDiv" v-if="index==3">
                      <div class="imgBox">
                        <img :src="echoImg(dates.coverImg)"/>
                        <span>{{toDate(dates.releaseTime,"yyyy-MM-dd")}}</span>
                      </div>
                      <div class="mapTitle">{{dates.journalisTitle}}</div>
                    </div>
                    <div class="newTitle" v-else>
                      <img class="coverImg" v-if="index==2" :src="echoImg(dates.coverImg)"/>
                      <span class="span3" v-if="index==1" :style="{background:color}">{{idxs+1}}</span>
                      <span class="span1">{{dates.journalisTitle}}</span>
                      <span class="span2" v-if="index!=1">{{toDate(dates.releaseTime,"yyyy-MM-dd")}}</span>
                    </div>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </template>
        <ul class="partners">
          <div class="titleBox ">
            <img class="icon" src="../images/handball-people.png"/>
            <span class="title1" :style="'color:'+color">合作伙伴</span>
          </div>
          <div class="containt">
            <ul :style="{ left: calleft + 'px', width: widthData + 'px' }" v-on:mouseover="stopMove()" v-on:mouseout="move()" class="imgBoxoul">
              <li v-for="(item, index) in linksList2" :key="index" ref="lis">
                <el-image class="img" :src="echoImg(item.imgUrl)" @click="openLinks(item.linkUrl)" fit="fit"></el-image>
              </li>
              <li v-for="(dates, idx) in linksList2" :key="'c_' + idx" ref="lis1">
                <el-image class="img" :src="echoImg(dates.imgUrl)" @click="openLinks(dates.linkUrl)" fit="fit"></el-image>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { selectLink,selectMapDepot,selectBanner,selectList,getTextarea } from "@/api/template";
// import AboutUs from "./aboutUs";
import { mapState } from "vuex";
export default {
  name: "Index",
  // components: { AboutUs },
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      linksList1:[],
      linksList2:[],
      bannerList: [],
      subjectList: [],
      synopsis:'',
      introduce:'',
      calleft: 0,
      speed: 1,
      timer: '',
      lyList:['../images/gg1.jpg','../images/gg2.jpg']
    };
  },
  created(){
    this.init()
    this.move()
    // console.log(this.color,5678)
  },
  computed: {
    widthData(){
      return 216 * Number(this.linksList2.length * 2)
    },
    ...mapState(["color"]),
  },
  mounted() {
    // let imgBox = document.getElementsByClassName("imgBoxoul")[0];
    // imgBox.innerHTML += imgBox.innerHTML;
  },
  methods: {
    init(){
      this.getSelectBanner()
      this.getSelectList()
      this.getSelectMapDepot()
      this.getSelectLink()
    },
    getSelectLink(){
      let self = this
      selectLink().then(res =>{
        if(res.code == 200){
          res.data.forEach(item =>{
            if(!item.imgUrl){
              this.linksList1.push(item)
            }else if(item.imgUrl){
              this.linksList2.push(item)
            }
          })
          this.linksList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectBanner(){
      let self = this
      selectBanner().then(res =>{
        if(res.code == 200){
          this.bannerList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
          // console.log(this.mapDepotList)
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectList(){
      let self = this
      selectList().then(res =>{
        if(res.code == 200){
          this.subjectList = res.data
          //.reverse()
          // console.log(this.subjectList)
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    openLinks(linkUrl){
      window.open(linkUrl,'_blank')
    },
    more(navigationName,id,type){
      this.$router.push({params: {name:navigationName,id:id,type: type},name:'sixth-list'})
    },
    goDetails(navigationName,id) {
      let data = {
        name: navigationName,
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/sixth-details')
    },
    getTextareaFun(journalisContent,type){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        if(type == 'synopsis'){
          this.synopsis = res.data.dataHtml
        }
        if(type == 'introduce'){
          this.introduce = res.data.dataHtml
        }
      })
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -this.linksList2.length*216) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
  },
};
</script>
<style lang="scss" scoped>
  .content{
    position: relative;
    .banner{
      width: 100%;
      height: 450px;
      background: url("../images/bannerBg.jpg") no-repeat center center;
      background-size: 100% 100%;
      .img{
        width: 100%;
        height: 100%;
      }
    }
    .main{
      width: 1160px;
      background: #fff;
      margin: -80px auto 0;
      padding: 30px 20px;

      .titleBox{
        position: relative;
        width: 100%;
        background: #f2f2f2;
        color: #666;
        font-size: 16px;
        height: 36px;
        line-height: 36px;
        .icon{
          vertical-align: middle;
          border-style: none;
          margin-right: 5px;
          margin-left: 10px;
        }
        .title1{
          font-weight: bold;
          color: #e60012;
        }
        .more{
          display: inline-block;
          width: 64px;
          text-align: center;
          position: absolute;
          right: 0;
          top: 0;
          height: 36px;
          line-height: 36px;
          background: #e60012;
          color: #fff;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .lunbo{
        position: relative;
        width: 410px;
        height: 282px;
        display: inline-block;
        cursor: pointer;
        .title{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          background: rgba(0,0,0,0.6);
          z-index: 99;
          color: #fff;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
        }
        .img{
          width: 100%;
        }
        /deep/ .el-carousel__arrow{
          background-color:rgba(31,45,61,.4);
        }
        /deep/.el-carousel__indicators{
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      .ul{
        display: inline-block;
        vertical-align: top;
        .li_0{
          display: inline-block;
          width: 380px;
          margin: 0 30px;
          cursor: pointer;
          .newTitle{
            height: 30px;
            line-height: 30px;;
            .span1{
              float: left;
              width: 75%;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .span2{
              float: right;
              width: 25%;
              text-align: right;
            }
          }
        }
        .li_1{
          display: inline-block;
          width: 310px;
          vertical-align: top;
          cursor: pointer;
          .ulBg{
            width: 90%;
            background: #f7f7f7;
            padding: 10px 5%;
          }
          .newTitle{
            width: 100%;
            height: 20px;
            line-height: 20px;
            margin-top: 5px;
            .span3{
              display: inline-block;
              width: 6%;
              background: #ed5d52;
              color: #fff;
              text-align: center;
              margin-right: 2%;
            }
            .span1{
              display: inline-block;
              width: 90%;
              height: 20px;
              line-height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
            }
          }
        }

      }
      .ul_2{
        width: 820px;
        margin-top: 20px;
        .coverImg{
          width: 142px;
          height: 90px;
          margin-right: 10px;
        }
        ul{
          padding-top: 18px;
          li{
            padding: 18px 0;
            border-bottom: 1px solid #eee;
            font-weight: bold;
            cursor: pointer;
            .span1{
              display: inline-block;
              width: 550px;
              font-size: 16px;
            }
            .span2{
              float: right;
            }
          }
          li:last-child{
            border-bottom: none;
          }
        }
      }
      .ul_3{
        display: inline-block;
        width: 310px;
        margin-left: 30px;
        margin-top: 20px;
        .titleBox{
          margin-bottom: 18px;
        }
        .li_3{
          width: 100%;
        }
        li{
          display: inline-block;
          width: 48%;
          cursor: pointer;
          .imgBox{
            position: relative;
            width: 145px;
            height: 106px;
            img{
              width: 100%;
              height: 100%;
            }
            span{
              position: absolute;
              bottom: 0;
              background: rgba(0,0,0,.6);
              color: #fff;
              width: 100%;
              height: 20px;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              left: 0;
            }
          }
          .mapTitle{
            width: 145px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
          }
        }
        li:nth-child(odd){//奇数行
          margin-right: 4%;
        }
        li:nth-child(even){//偶数行

        }
      }
      .ul_4{
        display: inline-block;
        width: 366px;
        height: 280px;
        margin-top: 20px;
        .newTitle {
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          .span1 {
            float: left;
            width: 75%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .span2 {
            float: right;
            width: 25%;
            text-align: right;
          }
        }
      }
      .ul_4:nth-child(odd){
        margin-right:30px;
        margin-left: 30px;
      }
      .partners{
        width: 100%;
        margin-top: 20px;
        .bTitle{
          height: 36px;
          line-height: 36px;
          background: #f2f2f2;
          font-size: 16px;
          font-weight: bold;
          color: #e60012;
          padding-left: 10px;
        }
        .containt {
          position: relative;
          //padding: 20px 0;
          overflow-y: auto;
          width: 100%;
          height: 168px;
          overflow: hidden;
          margin: 0 auto;
          ul {
            margin: 0 auto;
            width: 100%;
            position: absolute;
            left: 0px;
            cursor: pointer;
            z-index: 10;
            overflow: hidden;
            li {
              float: left;
              height: 120px;
              padding: 30px 48px 0;
              overflow: hidden;
              text-align: center;
              .img{
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
</style>
